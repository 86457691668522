<template>
  <div class>
    <div class="container">
      <div v-if="loading" class="loader">Loading...</div>
      <div class="welcome-flow-container" v-if="!loading">
        <div class="row d-flex justify-content-center align-items-center vh-100">
          <div class="col-12 col-lg-5 order-lg-3">
            <div class="widget mb-3">
              <div class="widget-splash splash-line"></div>
              <div class="widget-body text-center">
                <h4>Verify Your Profile</h4>
                <p>You have been invited to join Excelerate!</p>
                <form @submit.prevent="activateUser">
                  <div class="form-group text-left">
                    <label>Activate your profile</label>
                    <input
                      type="text"
                      v-model="code"
                      name="code"
                      class="form-control form-control-lg"
                      placeholder="Enter 6-digit email code"
                      autofocus
                      required
                    />
                    <span class="text-error">{{failCode}}</span>
                  </div>
                      <div class="form-group text-left">
                      <button class="btn btn-primary btn-block btn-lg">Next</button>
                  </div>
                </form>
              </div>
            </div>
            <!-- /widget -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: function() {
    return {
      loading: false,
      code: null,
      // failCode: null,
      // onboardingCompany: null
    };
  },
  mounted: function() {
   var vm = this;
   var uid = this.$route.params.uid // unique id from the email for api
   vm.$store.dispatch("startOnboarding", uid)
  //  .then(vm.onboardingCompany = vm.$store.getters.onboardingCompany
   //todo maybe set the rest of the onboarding data too???
  //  )
   // console.warn("🚀 ~ file: OnboardingProfessional.vue:51 ~ uid", uid)
  },
  computed: {
    onboardingCompany: function(){
      return this.$store.getters.onboardingCompany
    },
    user_id: function(){
      return this.$store.getters.onboardingData
    },
    failCode: function(){
      return this.$store.getters.failCode
    }
  },
  methods: {
   activateUser(){
    // console.log('yo')
    // todo make the api request with the otp
    var vm = this;
    vm.$store.dispatch('verifyUser', {otp: vm.code, user_id: vm.user_id.id})
    // .then(
    //   vm.failCode = vm.$store.getters.failCode
    // )
   }
  }
};
</script>

<style scoped>
#user-pages .welcome-flow-container .welcome-message {
  margin-top: 0px !important;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.text-error{ 
  color: red
}
</style>